<template>
  <page-main
    title="Cover Letter Customization"
    nextText="Go to Saved Cover Letters"
    :displayBeta="true"
    buttonTabIndex="3"
    eventName="nav"
    @nav="validateAndNavigate"
    @save="save"
  >
    <template v-slot:aside>
      <div>
        <ul>
          <p>This is where you can make any changes to your cover letter, including replacing it completely. Or if you’re satisfied with your current letter, you can simply download it and go!</p>
          <p></p>
          <li>Like your cover letter exactly as it is? You can download it as a pdf, a Word doc, or both.</li>
          <p></p>
          <li>Want to make specific changes to your letter? Click ‘Switch to Edit Mode’ to personalize the text, but be sure to save your changes when you’re done!</li>
          <p></p>
          <li>Did you edit either your target industry or company, or are you just dissatisfied with your current letter? Click ‘Generate New Letter’ to make a new one from scratch, but be careful: this action can’t be undone!</li>
        </ul>
      </div>
    </template>
    <template v-slot:nav>
      <modal cardTitle="Tips" ctaText="Need a few tips?" :accentButton="true">
        <span class="font-weight-bold">Tips</span>
        <ul>
          <li><span class="font-weight-bold">Tip #1: </span>You must save any changes made in edit mode if you want to keep them, otherwise they’ll be lost.</li>
          <li><span class="font-weight-bold">Tip #2: </span>Clicking the ‘Generate New Letter’ button will completely replace your current letter text. Only use this feature if you’re sure you don’t want to keep it!</li>
          <li><span class="font-weight-bold">Tip #3: </span>Not sure if you want to keep a cover letter or not? Use the 'Download Word (.docx)' button to save a copy of the current text before generating a replacement letter.</li>
        </ul>
      </modal>
      <v-btn
        outlined
        class="mt-4"
        block
        @click="navToContactUs"
        >
        Any feedback? Contact us!
      </v-btn>
    </template>
    <div class="py-2 px-4 elevation-2 page-error-bar" v-if="hasError">
      <v-icon color="red">mdi-alert-outline</v-icon>
      <span class="red--text pl-3">{{ this.errorMessage }}</span>
    </div>
    <v-container fluid>
      <v-overlay color="primary" :absolute="true" v-if="loading">
        <v-progress-circular
          indeterminate
          color="accent"
          size="64"
        ></v-progress-circular>
      </v-overlay>
      <v-row justify="end">
        <v-col cols="12" sm="4" xl="3">
          <v-btn
            block
            outlined
            @click="reGenerate = true"
            :disabled="isAnalyzing || isEditMode"
            id="re-optimize"
            class="mt-3"
          >Generate New Letter</v-btn>
          <v-btn
            block
            outlined
            @click="downloadPDF()"
            id="download-pdf"
            :disabled="isAnalyzing || isEditMode"
            class="mt-3"
          >Download PDF</v-btn>
          <v-btn
            block
            outlined
            @click="downloadWord()"
            :disabled="isAnalyzing || isEditMode"
            id="download-word"
            class="mt-3"
          >Download Word (.docx)</v-btn>
        </v-col>
        <v-col v-if="aiOptimizedPhrasing || !showAiOptimizationError" cols="12" style="text-align:center;" >
          <v-btn
          @click="editMode"
          outlined
          class="mt-3"
          :disabled="isAnalyzing || isEditMode"
          >Switch to Edit Mode</v-btn>
        </v-col>
        <v-col class="text-right">
          {{ (coverLetterAttempts) }} / {{ (maxCoverLetterAttempts) }} letters created for the month
        </v-col>
        <v-col cols="12" style=" padding-bottom: 48px;">
          <v-card v-if="isAnalyzing || aiOptimizedPhrasing || !showAiOptimizationError" class="pa-3" >
            <v-row>
              <v-col cols="12">
                <div v-if="isAnalyzing" class="text-center">
                  <p><b>Analyzing...</b></p>
                  <v-img
                    aspect-ratio="1"
                    width="50"
                    max-width="50"
                    class="mx-auto"
                    :src="require('@/assets/logos/logo_blue_1.png')"
                    contain
                  ></v-img>
                  <h3 class="text-body-1 font-italic text--secondary">
                    This may take a minute to complete
                  </h3>
                </div>
                <div v-else-if="showAiOptimizationError">
                  <v-card-text class="text--primary text-body-1 pt-4 pb-3 text-center">
                    <h3 class="pt-2">Oops. Something went wrong.</h3>
                    <h3 class="text-body-1 font-italic text--secondary">
                      {{ errorMessage }}
                    </h3>
                  </v-card-text>
                  <v-card-actions
                    :class="
                      $vuetify.breakpoint.xsOnly
                        ? 'flex-wrap justify-center'
                        : 'flex-wrap justify-end'
                    "
                  >
                  </v-card-actions>
                </div>
                <div v-else>
                  <div v-if="isEditMode">
                    <v-textarea 
                      label="Edit AI Optimized Phrasing"
                      v-model="aiOptimizedDisplayCopy"
                      rows="20"
                      
                    ></v-textarea>
                    <v-row>
                      <v-col cols="6">
                        <v-btn 
                        outlined 
                        @click="finishEditMode(false)" 
                        class="mt-3"
                        >Cancel</v-btn>
                      </v-col>
                      <v-col cols="6" class="text-right">
                        <v-btn 
                        outlined 
                        @click="finishEditMode(true)" 
                        class="mt-3"
                        >Save</v-btn>
                      </v-col>
                    </v-row>
                  </div>
                  <div v-else>
                    <div v-for="(item, i) in aiOptimizedDisplay" style="white-space: pre-wrap;"
                    :key="i"
                    >
                      {{ item }}
                    </div>
                  </div>
                </div>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
    <r-dialog v-model="premiumBanner" persistent>
      <v-card-text class="text--primary text-body-1 pt-4 pb-3">
        <h4 class="pt-2 text-center">Oops! Looks like you will need Resumation Plus to use Cover Letter Creator feature!
        </h4>
        <p class="mt-2">Upgrade to get unlimited use of the Optimize with AI features, hide/show parts of your resume
          in-tool (customizations), save and store multiple resumes, and much more for only $5 a month!</p>
        <h4 class="pt-2 text-center">Would you like to upgrade to Resumation Plus?</h4>
      </v-card-text>
      <v-card-actions :class="$vuetify.breakpoint.xsOnly ? 'flex-wrap justify-center' : 'flex-wrap justify-end'">
        <v-btn color="primary" outlined text @click="navToPremiumPage()"> Yes - let’s do it! </v-btn>
      </v-card-actions>
    </r-dialog>
    <r-dialog 
      v-model="cancelSave" 
      persistent
      title="Confirm Cancel Edits"
    >
      <v-card-text class="text--primary text-body-1 pt-4 pb-3">
        <p class="pt-2 text-center">Are you sure you want to cancel your edits?
        </p>
      </v-card-text>
      <v-card-actions class="d-flex justify-space-between align-end pb-3">
        <v-btn color="gray" outlined text @click="confirmSaveDialog(false)">No - continue editing</v-btn>
        <v-btn color="primary" outlined text @click="confirmSaveDialog(true)">Yes - cancel edits</v-btn>
      </v-card-actions>
    </r-dialog>
    <r-dialog 
      v-model="confirmSave" 
      persistent
      title="Confirm Save Edits"
    >
      <v-overlay color="primary" :absolute="true" v-if="loading">
        <v-progress-circular
          indeterminate
          color="accent"
          size="64"
        ></v-progress-circular>
      </v-overlay>
      <v-card-text class="text--primary text-body-1 pt-4 pb-3">
        <p>Are you sure you want to save your edits? This will overwrite your previous letter and can't be undone.</p>
      </v-card-text>
      <v-card-actions class="d-flex justify-space-between align-end pb-3">
        <v-btn color="gray" outlined text @click="confirmSaveDialog(false)">No - do not save</v-btn>
        <v-btn color="primary" outlined text @click="confirmSaveDialog(true)">Yes - save edits</v-btn>
      </v-card-actions>
    </r-dialog>
    <r-dialog 
      v-model="reGenerate" 
      persistent
      title="Confirm New Letter Generation"
    >
      <v-card-text class="text--primary text-body-1 pt-4 pb-3">
        <p class="pt-2 text-center">Are you sure you want to generate a new letter? This will overwrite your previous letter and can't be undone.
        </p>
      </v-card-text>
      <v-card-actions class="d-flex justify-space-between align-end pb-3">
        <v-btn color="gray" outlined text @click="confirmReGenerate(false)">Cancel</v-btn>
        <v-btn color="primary" outlined text @click="confirmReGenerate(true)">Yes - generate new letter</v-btn>
      </v-card-actions>
    </r-dialog>
  </page-main>
</template>

<script>

import { mapFields } from "vuex-map-fields";
import { mapActions } from "vuex";
import * as resApi from "../../api/ResumeApi";
import * as log from "../../utility/logging/logger";
import PizZip from 'pizzip';
import Docxtemplater from 'docxtemplater';
import CoverLetterReportBuilder from "../../docxBuilder/coverLetterReportBuilder";
import { saveAs } from "file-saver";



export default {
  name: "CoverLetterReview",
  data() {
    return {
      blob: null,
      premiumBanner: false,
      isAnalyzing: false,
      aiOptimizedDisplay: "",
      aiOptimizedDisplayCopy: "",
      aiOptimizationStatus: "idle",
      errorMessage: "",
      hasError: false,
      context: "",
      isEditMode: false,
      cancelSave: false,
      confirmSave: false,
      reGenerate: false,
      loading: false,

      saveResumeNote: "",

    };
  },
  methods: {
    async save() {
      try {
        await this.buildFile();
        await this.updateCoverLetter();
        await this.updateCoverLetterFile();      
      } catch (e) {
        log.logError("Error saving cover letter.");
      }
    },
    async validateAndNavigate() {
      this.loading = true;
      await this.save();
      if(this.isError)
      {
        this.hasError = true;
        this.errorMessage = "Error saving cover letter information";
        this.loading = false;
      } else {
        this.loading = false;
        this.nav('/SavedCoverLetters');
      }
    },    
    generateAiResponse() {
      // Call ai to optimize
      this.optimizeWithAI();
    },
    async downloadWord() {
      await this.buildFile();
      saveAs(this.blob, this.fileName);
    },
    async downloadPDF() {
      const link = document.createElement("a");
      link.href = this.pdfRef;
      link.target = "_blank";
      document.body.append(link);
      link.click();
      link.remove();
      setTimeout(() => URL.revokeObjectURL(link.href), 7000);
    },
    editMode() {
      this.aiOptimizedDisplayCopy = this.aiOptimizedPhrasing;
      this.isEditMode = true;
    },
    finishEditMode(isSave) {
      if( isSave )
      {
        this.confirmSave = true;
      }
      else 
      {
        this.cancelSave = true;
      }
    },
    async confirmSaveDialog(confirmation) {
      if (confirmation)
      { 
        if( this.confirmSave )
        {
          this.loading = true;
          this.aiOptimizedPhrasing = this.aiOptimizedDisplayCopy;
          await this.buildFile();
          await this.updateCoverLetter();
          await this.updateCoverLetterFile();
          await this.loadCoverLetter( {reviewId: this.coverLetterId} );  
          this.buildReportDisplay();
          if(this.isError)
          {
            this.hasError = true;
            this.errorMessage = "Error saving changes";
          }
        }
        this.loading = false;
        this.isEditMode = false;
      }
        this.cancelSave = false;
        this.confirmSave = false;
      
    },
    confirmReGenerate(confirmation) {
      if (confirmation )
      {
       this.generateAiResponse();
       this.reGenerate = false;
      }
      else 
      {
        this.reGenerate = false;
      }
    },
    async optimizeWithAI() {
      this.isAnalyzing = true;
      try {
        log.logInformation("Refreshing user premium and attempts status...");
        await this.refreshPremium();
        if (!this.isPremium ) 
        {
          this.dialog = false;
          this.premiumBanner = true;
          this.isAnalyzing = false;
        } 
        else 
        {
          if ( !this.selectedResume)
          {
            this.aiOptimizationStatus = "error";
            this.errorMessage = "Need to select a resume first before continuing."
            this.isAnalyzing = false;
            return;
          }

          this.context = "cover-letter";
          let reportData = await this.buildReportDataString();
          if (reportData == "Error")
          {
            return null;
          }
          
          // Might need to change what store FetchAiOptimzied depending if I write this function in CoverLetterDataFunctions
          await this.refreshPremium();
          if(this.coverLetterAttempts >= this.maxCoverLetterAttempts) {
            this.aiOptimizationStatus = "error";
            this.hasError = true;
            this.errorMessage = "Unable to generate new letter. Maximum monthly limit has been reached."
          } else {
            let result = await resApi.FetchAiOptimizedPhrasing({
              resumeId: this.$store.state.resumeId,
              phrasing: reportData,
              context: this.context,
            });
            if (!result.success) {
              log.logError(
                "Failed to get AI optimized description",
                "optimizeWithAI"
              );
              this.aiOptimizationStatus = "error";
              this.hasError = true;
              this.errorMessage = "Failed to get AI generated cover letter. Please try again in a couple minutes."
            } else {
              this.aiOptimizedPhrasing = result.data.optimizedPhrasing;
              await this.buildFile();
              await this.updateCoverLetter();
              await this.updateCoverLetterFile();
              await this.loadCoverLetter( {reviewId: this.coverLetterId} );
              this.buildReportDisplay();
              await this.refreshPremium();
            }
          }
          this.isAnalyzing = false;
        }
      } catch (e) {
        log.logError("Failed to get AI optimized description", e);
        this.aiOptimizationStatus = "error";
        this.hasError = true;
        this.errorMessage = "Failed to get AI generated cover letter. Please try again in a couple minutes."
        this.isAnalyzing = false;
      }
    },
    async buildReportDataString() {
      try {
        const resume = this.resumes.find(r => r.id === this.selectedResume.id);
        if (!resume) {
          return 'Error';
        }
        const fileRef = resume.fileRef;
        const response = await fetch(fileRef);
        const arrayBuffer = await response.arrayBuffer();
        const zip = new PizZip(arrayBuffer);
        const doc = new Docxtemplater(zip, {
          paragraphLoop: true,
          linebreaks: true,
        });

        const resumeText = doc.getFullText();
        const skills = this.selectedSkills.map(skill => skill.text).join(", ");

        let result = resumeText.concat(
          " Target Industry: " + this.targetedIndustry,
          " , Skills: " + skills,
          " , Industry Interest: " + this.industryInterest,
          " , Company Name: " + this.companyName,
          " , Company Description: " + this.companyDescription,
          " , Position Name: " + this.positionName,
          " , Role Interest: " + this.roleInterest,
          " , Working Goals: " + this.workingGoals,
          " , Cover Letter Feel/ sound like: " + this.coverLetterFeel,
        );
        return(result);
      } catch (e) {
        log.logError("Failed to get AI optimized description", e);
        this.aiOptimizationStatus = "error";
        this.errorMessage = "Failed to generate data to be sent to AI"
        this.isAnalyzing = false;
      }
    },
    async buildFile(){
      const builder = new CoverLetterReportBuilder();
      builder.CreateText(this.aiOptimizedPhrasing);
      this.blob = await builder.BuildDocx();      
      this.reportBase64 = await this.toBase64(this.blob);
      this.fileName = this.name + ".docx";
    },
    buildReportDisplay() {
      const text = this.aiOptimizedPhrasing;
      const lines = text.split('\n');
      this.aiOptimizedDisplay = lines;
    },
    async toBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () =>
          resolve(reader.result.slice(reader.result.indexOf("base64,", 0) + 7));
        reader.onerror = (error) => reject(error);
      });
    },
    buildFileName(extension) {
      return `${this.nameDisplay} Resume.${extension}`;
    },
    nav(routerPath) {
      // Need next page to go to.
      this.$router.push({ path: routerPath });
    },
    navToPremiumPage() {
      this.dialog = false;
      this.$router.push({ path: '/Upgrade' });
    },
    navToContactUs() {
      window.open('https://www.resumation.net/contact', '_blank');
    },
    ...mapActions("activeCoverLetter", ["updateCoverLetter"]),
    ...mapActions("activeCoverLetter", ["updateCoverLetterFile"]),
    ...mapActions("activeCoverLetter", ["loadCoverLetter"]),
    ...mapActions("auth", ["refreshPremium"]),
  },
  computed: {
    ...mapFields("auth", {
      isPremium: "isPremium",
      maxCoverLetterAttempts: "maxCoverLetterAttempts",
      coverLetterAttempts: "coverLetterAttempts"      
    }),
    ...mapFields("coverLetter", [ "coverLetterList" ]),
    ...mapFields("userSubmissions", {
        resumes: "resumes",
      }),
    ...mapFields("activeCoverLetter", [
      "aiOptimizedPhrasing",
      "fileName",
      "pdfRef",
      "isError",
      "isPageAllowed",
      "name",
      "reportBase64",
      "coverLetterId",
      "selectedResume",
      "targetedIndustry",
      "selectedSkills",
      "industryInterest",
      "companyName",
      "companyDescription",
      "positionName",
      "roleInterest",
      "workingGoals",
      "coverLetterFeel",
      "coverLetterChanges"
    ]),
    ...mapFields("userSubmissions", {
      resumes: "resumes",
    }),
    showAiOptimizationError() {
      return this.aiOptimizationStatus === "error";
    },
    canProceedEmail() {
      return this.authType != "resumation" || this.emailConfirmed;
    },
  },
  async mounted () { 
    await this.refreshPremium();
    if (!this.isPremium ) 
    {
      this.dialog = false;
      this.premiumBanner = true;
    } 
   
    if (!this.isPageAllowed)
    {
      this.nav('/SavedCoverLetters');
    }
    if (this.isError)
    {
      this.hasError = true;
      this.errorMessage = "Error developing cover letter."
    }
    if (this.aiOptimizedPhrasing == "" && !this.isError)
    { 
      this.optimizeWithAI();
    } else {
      this.buildReportDisplay();
    }
  },
  beforeRouteLeave (to, from, next) {
    if(to.fullPath == "/CoverLetterTargetCompany")
    {
      this.isPageAllowed = true;
    } else {
      this.isPageAllowed = false;
    }
    next();
  },
};
</script>
